import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Container,
  Stack,
  useTheme,
} from "@mui/material";
import bioImage from "../img/BIO.png";
import miniGalerie from "../img/MINI-GALERIE.png";
import imagPalmares from "../img/imgPalmares.png";
import mini1 from "../img/miniG.JPG";
import mini2 from "../img/miniG2.JPG";
import mini3 from "../img/miniG3.JPG";
import mini4 from "../img/miniG4.JPG";
const ParcoursSection = () => {
  const theme = useTheme();
  return (
    <Container sx={{ marginTop: 7 }}>
      <Stack direction="column" spacing={4}>
        <Card sx={{ padding: 4, boxShadow: "none" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <CardMedia
                component="img"
                image={bioImage}
                alt="Anaïs Vincent"
                sx={{
                  borderRadius: 2,
                  width: "100%",
                }}
              ></CardMedia>{" "}
            </Grid>
            <Grid item xs={12} md={8}>
              <CardContent>
                <Typography variant="h4">Mon parcours</Typography>
                <Typography variant="h2">Qui suis-je ?</Typography>
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  Je m'appelle Anaïs Vincent, j’ai 24 ans et je viens de la
                  Drôme, dans les pré-Alpes françaises. Depuis toujours, le
                  sport accompagne mon quotidien. Enfant, je pratiquais de
                  nombreuses disciplines en pleine nature.
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  Mais le 6 mars 2021, ma vie a basculé : une chute en falaise
                  m’a laissée paraplégique. J’ai alors traversé une longue
                  période de rééducation, faite d'efforts intenses, de
                  détermination et d’un apprentissage constant de résilience.
                  Dès que j’ai pu, je me suis tournée vers le Handisport, et
                  tout naturellement, j’ai découvert le paracyclisme.
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  Des rencontres marquantes, notamment avec Florian Jouanny,
                  m’ont poussée à aller encore plus loin, jusqu’à participer à
                  mes premiers Jeux paralympiques à Paris en 2024. Sur cette
                  scène internationale, j’ai tout donné et j’ai terminé 5e. Mais
                  ce n’est qu’un début. Les Jeux paralympiques de Los Angeles
                  2028 sont déjà en ligne de mire. D’ici là, je vais continuer à
                  m’entraîner, à repousser mes limites, et à donner le meilleur
                  de moi-même pour aller chercher de nouveaux podiums et de
                  nouveaux défis.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  Ma passion et ma détermination m’ont menée ici, et avec vous,
                  je suis impatiente d’écrire la suite de mon histoire.
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={3}>
            <Card
              sx={{
                boxShadow: "none",
              }}
            >
              <CardMedia
                component="img"
                image={mini1}
                alt=""
                sx={{ height: 300 }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card
              sx={{
                boxShadow: "none",
              }}
            >
              <CardMedia
                component="img"
                image={mini2}
                alt=" "
                sx={{ height: 300 }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card
              sx={{
                boxShadow: "none",
              }}
            >
              <CardMedia
                component="img"
                image={mini3}
                alt=" "
                sx={{ height: 300 }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card sx={{ boxShadow: "none" }}>
              <CardMedia
                component="img"
                image={mini4}
                alt=""
                sx={{ height: 300 }}
              />
            </Card>
          </Grid>
        </Grid>

        <Box sx={{ padding: { xs: 1, md: 4 } }}>
          <Card
            sx={{
              backgroundColor: theme.palette.primary.light,
              borderRadius: 2,
              boxShadow: "none",
            }}
          >
            <Grid container spacing={4} padding={4}>
              <Grid item xs={12} md={8}>
                <CardContent>
                  <Typography
                    variant="h2"
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "30px",
                    }}
                  >
                    <span
                      role="img"
                      aria-label="trophy"
                      style={{ marginRight: "0.5rem" }}
                    >
                      🏆
                    </span>
                    Mon palmarès
                  </Typography>
                  <Typography variant="body1" sx={{ marginTop: 2 }}>
                    <ul>
                      <li>
                        <strong>Championnats du Monde 🌈</strong>
                        <br />- 2024 : Zurich 🇨🇭 <br />
                        🥉 Course en ligne <br />
                        🥉 Contre-la-montre
                        <br />- 2023 : Glasgow 🏴󠁧󠁢󠁳 <br />
                        🥉 Course en ligne
                        <br />
                        🥉 Contre-la-montre
                        <br />
                        <br />
                      </li>

                      <li>
                        <strong>Jeux Paralympiques de Paris 2024</strong>
                        <br />- 5ème place : <br />
                        Course en ligne <br />
                        Contre-la-montre
                        <br />
                        <br />
                      </li>

                      <li>
                        <strong>Coupes du Monde 2024 🌍</strong>
                        <br />- Maniago 🇮🇹
                        <br />
                        🏅 contre-la-montre <br />
                        - Ostende 🇧🇪
                        <br />
                        🥈 Contre-la-montre
                        <br />
                        🥈 Relais par équipe
                        <br />
                        <br />
                      </li>

                      <li>
                        <strong>Championnats d’Europe 🇪🇺</strong>
                        <br />- 2023 : <br />
                        🏅relais par équipe <br />
                        🥉contre-la-montre
                        <br />
                        <br />
                      </li>

                      <li>
                        <strong>Championnats de France 🇫🇷</strong>
                        <br />- 2023 & 2024 :<br />
                        🏅 Course en ligne <br />
                        🏅 Contre-la-montre
                        <br />
                        <br />
                      </li>
                    </ul>
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item xs={12} md={4}>
                <CardMedia
                  component="img"
                  image={imagPalmares}
                  alt="Anaïs Vincent"
                  sx={{
                    borderRadius: 2,
                    width: {
                      xs: "100%", // Width is 100% on extra small screens
                      md: "100%", // Width is 100% from medium screens and up
                    },
                    height: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Stack>
    </Container>
  );
};

export default ParcoursSection;
