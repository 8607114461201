import React from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Divider,
} from "@mui/material";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";

const ObjectivesSection = () => {
  return (
    <Container sx={{ marginTop: 7, padding: 4 }}>
      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="h4" sx={{ marginBottom: 1 }}>
          Mes objectifs
        </Typography>
        <Typography variant="h2" sx={{}}>
          Mes Projets
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card sx={{ border: "1px solid #F3EDF2", padding: 2 }}>
            <CardContent sx={{ textAlign: "center" }}>
              <EventAvailableRoundedIcon
                sx={{ fontSize: 40, color: "#5A6039" }}
              />
              <Typography variant="h4" sx={{ marginTop: 2, color: "#ab47bc" }}>
                Objectif Majeur
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", marginTop: 1 }}
              >
                Jeux Paralympiques de Los Angeles 2028 
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                du mardi 15 août au dimanche 27 août 2028
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ border: "1px solid #F3EDF2", padding: 2 }}>
            <CardContent sx={{ textAlign: "center" }}>
              <EventAvailableRoundedIcon
                sx={{ fontSize: 40, color: "#5A6039" }}
              />
              <Typography variant="h4" sx={{ marginTop: 2, color: "#ab47bc" }}>
                Objectif secondaire
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", marginTop: 1 }}
              >
                Championnats du Monde 2025
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                du 28 au 31 août - Renaix (Belgique)
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: 4 }}>
        <Typography
          variant="h2"
          sx={{
            marginBottom: 2,
          }}
        >
          Prochains événements
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 1 }}>
          • Coupes du Monde au printemps 2025
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 1 }}>
          • Championnats d'Europe et du Monde en été 2025
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 1 }}>
          • Enfin sur le long terme l'objectif est de toujours progresser sur le
          circuit international pour devenir la meilleure que je puisse être.
        </Typography>
      </Box>
    </Container>
  );
};

export default ObjectivesSection;
