import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import api from "../api";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    lastname: "",
    firstname: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await api.post("/send-email", {
        to: formData.email,
        subject: "Nouveau message de contact",
        message: `${formData.message}`,
        name: `${formData.firstname} ${formData.lastname}`,
        phone: formData.phone,
      });

      if (response.status === 200) {
        alert("Message envoyé avec succès!");
        setFormData({
          lastname: "",
          firstname: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        alert("Échec de l'envoi du message.");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du message : ", error);
      alert("Une erreur s'est produite.");
    }
  };

  return (
    <Container sx={{ marginTop: 7, padding: 4 }}>
      <Typography variant="h2" sx={{ textAlign: "center", marginBottom: 4 }}>
        Contactez Moi
      </Typography>
      <Box component="form" sx={{ mt: 3 }} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="lastname"
              label="Nom"
              name="lastname"
              autoComplete="name"
              variant="outlined"
              value={formData.lastname}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="firstname"
              label="Prénom"
              name="firstname"
              autoComplete="firstname"
              variant="outlined"
              value={formData.firstname}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="phone"
              label="Téléphone"
              name="phone"
              autoComplete="phone"
              variant="outlined"
              value={formData.phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="message"
              label="Message"
              name="message"
              autoComplete="message"
              multiline
              rows={4}
              variant="outlined"
              value={formData.message}
              onChange={handleChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button type="submit" variant="contained" color="primary">
              Envoyer le message
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ContactForm;
